<template>

  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filtros</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Tipo de usuario</label>
          <v-select
              v-model="typeUserFilter"
              label="text"
              :options="typeUserOptions"
              class="w-100"
              :reduce="option => option.value"
              :searchable="false"
              @input="updateFilters"
          />

        </b-col>

        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Estado</label>
          <v-select
              v-model="estadoFilter"
              label="text"
              :options="estadoOptions"
              class="w-100"
              :reduce="option => option.value"
              :searchable="false"
              @input="updateFilters"
          />
        </b-col>
      </b-row>

    </b-card-body>

  </b-card>

</template>

<script>

import { BCard, BCardHeader, BCardBody, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

import { fetchTypeOptions, fetchStatusOptions } from '@/modules/common/utils'
import { mapMutations } from 'vuex'

export default {
  name: 'UsuariosFilters',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    vSelect,
  },

  data(){
    return {
      estadoFilter: null,
      estadoOptions: fetchStatusOptions(),
      typeUserFilter: null,
      typeUserOptions: fetchTypeOptions()
    }
  },

  methods: {

    ...mapMutations('usuario-module', ['setFiltersCriteria']),

    updateFilters(){

      const filtersCriteria = []
      if ( this.typeUserFilter != null )
        filtersCriteria.push( { field: 'usrType', values: [ this.typeUserFilter ] } )
      if ( this.estadoFilter != null )
        filtersCriteria.push( { field: 'usrEstado', values: [ this.estadoFilter ] } )

      this.setFiltersCriteria( filtersCriteria )

    }
  },



  created() {


  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

